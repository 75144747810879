.main {
  position: relative;
}

.main:before {
  content: '';
  width: 3px;
  height: 100%;
  background: white;
  position: absolute;
  left: 1rem;
  top: 0;
  transform: translateX(-50%);
  z-index: 1;
}

.main:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 1rem;
    border: none;
    border-left: 7px dashed;
    @apply border-dark-bg;
    height: 100px;
    transform: translateX(-50%);
    z-index: 1;
}
