.main:before {
  content: '';
  position: absolute;
  width: 20%;
  height: 400px;
  border-radius: 9999px;
  -webkit-box-shadow: 0px 0px 50px 13px #fff;
  box-shadow: 0px 0px 500px 13px #fff;
  left: 50%;
  transform: translate(-50%, -100%);
  top: 0;
}

.li {
  position: relative;
  @apply transition-all duration-200 cursor-pointer;
}

.li:after {
  content: '';
  width: 100%;
  position: absolute;
  height: 2px;
  bottom: 0;
  left: 0%;
  @apply bg-white transition-all duration-200;
}

.li:hover {
  @apply text-main;
}

.li:hover::after {
  @apply bg-main;
}
