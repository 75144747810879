.main:before, .main:after {
    position: absolute;
    content: '';
    width: 100px;
    top: 0;
    height: 100%;
    background: linear-gradient(90deg, black, transparent);
    z-index: 1
}

.main:before {
  left: 0;
}

.main:after {
  right: 0;
  transform: scaleX(-1);
}